import type { FC } from 'react';
import styled from 'styled-components';

import { Anchor } from 'src/components/common/atoms';
import { media, styleGaramond } from 'src/styles';

type Props = {
  className?: string;
  indexLink: string;
  nextLink?: string;
  nextLabel?: string;
  previousLink?: string;
  previousLabel?: string;
};

export const Pagination: FC<Props> = ({
  className,
  indexLink,
  nextLink,
  nextLabel,
  previousLink,
  previousLabel,
}) => (
  <Wrapper className={className || ''}>
    <Item aria-hidden={!previousLink}>
      <StyledAnchor to={previousLink || ''}>
        {previousLabel ?? 'prev'}
      </StyledAnchor>
    </Item>
    <Item>
      <StyledAnchor to={indexLink}>index</StyledAnchor>
    </Item>
    <Item aria-hidden={!nextLink}>
      <StyledAnchor to={nextLink || ''}>{nextLabel ?? 'next'}</StyledAnchor>
    </Item>
  </Wrapper>
);

const StyledAnchor = styled(Anchor)`
  font-size: ${({ theme }) => theme.font.size.small.sp};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &[href=''] {
    pointer-events: none;
    color: ${({ theme }) => theme.colors.gray};
  }
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.normal.pc};
  `}
`;

const Item = styled.li``;

const Wrapper = styled.ul`
  ${styleGaramond}
  line-height: 1;
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`;

export default Pagination;
