import type { FC, MouseEventHandler, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'src/components/common/atoms';
import { StyledGridImageItem } from 'src/components/common/grid/styled-grid-image-item';

type Props = {
  className?: string;
  children?: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  ariaLabel?: string;
};

export const Item: FC<Props> = ({
  className,
  children,
  onClick,
  ariaLabel,
}) => (
  <Wrapper className={className || ''}>
    <StyledButton onClick={onClick} aria-label={ariaLabel || undefined}>
      <StyledGridImageItem>{children}</StyledGridImageItem>
    </StyledButton>
  </Wrapper>
);

const HoverStyle = css`
  outline: none;
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    /* background-color: white; */
    /* mix-blend-mode: difference; */
    /* mix-blend-mode: soft-light; */
  }
`;

const StyledButton = styled(Button)`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  vertical-align: top;
  &:hover,
  &.focus-visible {
    ${HoverStyle}
  }
  &:focus-visible {
    ${HoverStyle}
  }
`;

const Wrapper = styled.div``;

export default Item;
