import type { PrismicRichTextProps } from '@prismicio/react';
import { graphql } from 'gatsby';
import type { FC } from 'react';
import styled from 'styled-components';

import { RichText } from 'src/components/common';
import { media, styleGaramond } from 'src/styles';
import { nonNullableFilter, useIsMobile } from 'src/utils';
import { Images } from './images';
import { Videos } from './videos';

type SingleBodyFragment =
  | readonly Queries.Maybe<Queries.CollectionSingleBodyFragment>[]
  | readonly Queries.Maybe<Queries.ProductSingleBodyFragment>[];
type Props = {
  className?: string;
  content: SingleBodyFragment;
};

export const fragment = graphql`
  fragment CollectionSingleBody on PrismicCollectionDataBodySlicesType {
    ... on PrismicCollectionDataBodySubContent {
      slice_type
      primary {
        sub_title {
          text
        }
        sub_content {
          richText
        }
      }
      items {
        ...CollectionSingleBodyImage
      }
    }
    ... on PrismicCollectionDataBodySubContentVideo {
      slice_type
      primary {
        sub_title {
          text
        }
        sub_content {
          richText
        }
      }
      items {
        ...CollectionSingleBodyVideo
      }
    }
  }

  fragment ProductSingleBody on PrismicProductDataBodySlicesType {
    ... on PrismicProductDataBodySubContent {
      slice_type
      primary {
        sub_title {
          text
        }
        sub_content {
          richText
        }
      }
      items {
        ...ProductSingleBodyImage
      }
    }
    ... on PrismicProductDataBodySubContentVideo {
      slice_type
      primary {
        sub_title {
          text
        }
        sub_content {
          richText
        }
      }
      items {
        ...ProductSingleBodyVideo
      }
    }
  }
`;

function hasImages(
  type: any,
  arg: any
): arg is
  | Queries.CollectionSingleBodyImageFragment[]
  | Queries.ProductSingleBodyImageFragment[] {
  return type === 'sub_content';
}

function hasVideo(
  type: any,
  arg: any
): arg is
  | Queries.CollectionSingleBodyVideoFragment[]
  | Queries.ProductSingleBodyVideoFragment[] {
  return type === 'sub_content_video';
}

export const SliceContent: FC<Props> = ({ className, content }) => {
  const isMobile = useIsMobile();
  const nonNullableContent = content.filter(nonNullableFilter);
  return (
    <Wrapper className={className || ''}>
      <SectionWrapper>
        {nonNullableContent.map(({ slice_type, primary, items }, index) => (
          <Section key={`slice-content-${index}`}>
            <Header>
              {primary?.sub_title?.text && (
                <Title>{primary.sub_title.text}</Title>
              )}
              {!isMobile && primary?.sub_content?.richText && (
                <Text>
                  <RichText
                    richText={
                      primary.sub_content
                        .richText as PrismicRichTextProps['field']
                    }
                  />
                </Text>
              )}
            </Header>
            <Content>
              {items && hasImages(slice_type, items) && (
                <Images items={items} />
              )}
              {items && hasVideo(slice_type, items) && <Videos items={items} />}
              {isMobile && primary?.sub_content?.richText && (
                <Text>
                  <RichText
                    richText={
                      primary.sub_content
                        .richText as PrismicRichTextProps['field']
                    }
                  />
                </Text>
              )}
            </Content>
          </Section>
        ))}
      </SectionWrapper>
    </Wrapper>
  );
};

const Title = styled.h2`
  text-transform: lowercase;
  font-size: ${({ theme }) => theme.font.size.normal.sp};
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.large.pc};
  `}
`;

const Header = styled.header`
  ${media.ipadVerticalOrMore`
    grid-column: 1 / 3;
  `}
`;

const Content = styled.div`
  ${media.lessThanIpadVertical`
    margin-top: 30px;
  `}
  ${media.ipadVerticalOrMore`
    grid-column: 3 / 7;
  `}
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.small.sp};
  line-height: 1.5;
  ${media.lessThanIpadVertical`
    margin-top: 22px;
  `}
  ${media.ipadVerticalOrMore`
    ${Title} + & {
      margin-top: 10px;
    }
    font-size: ${({ theme }) => theme.font.size.small.pc};
  `};
`;

const Section = styled.section`
  & + & {
    margin-top: 45px;
  }
  ${media.lessThanIpadVertical`
    text-align: center;
  `}
  ${media.ipadVerticalOrMore`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: ${({ theme }) => theme.structure.margin.grid.pc}px;
    & + & {
      margin-top: 120px;
    }
  `}
`;

const SectionWrapper = styled.div`
  margin-top: 45px;
  ${media.ipadVerticalOrMore`
    margin-top: 120px;
  `}
`;

const Wrapper = styled.div`
  ${styleGaramond}
`;

export default SliceContent;
