import { PrismicRichText, type PrismicRichTextProps } from '@prismicio/react';
import type { FC } from 'react';

import { Anchor } from 'src/components/common/atoms/anchor';
import { linkResolver } from './link-resolver';

type Props = {
  richText: PrismicRichTextProps['field'];
};

export const RichText: FC<Props> = ({ richText }) => (
  <PrismicRichText
    field={richText}
    components={{
      hyperlink: ({ node, children, key }) => {
        if (node.data.link_type === 'Web' || node.data.link_type === 'Media') {
          return (
            <a key={key} href={node.data.url} target="_blank" rel="noreferrer">
              {children}
            </a>
          );
        } else if (node.data.link_type === 'Document') {
          return (
            <Anchor key={key} to={linkResolver(node.data)}>
              {children}
            </Anchor>
          );
        }
        return null;
      },
    }}
  />
);

export default RichText;
