import { useCallback, useEffect, useState, type FC } from 'react';
import styled from 'styled-components';

import { MenuButton } from 'src/components/common/menu-button';
import { media } from 'src/styles';
import { useIsMobile, useSlug } from 'src/utils';
import { MenuList } from './menu-list';

type Props = {
  className?: string;
};

const menus = [
  {
    label: 'foreword',
    uri: '/foreword/',
  },
  {
    label: 'collection',
    uri: '/collection/',
  },
  {
    label: 'products',
    uri: '/products/',
  },
  {
    label: 'about',
    uri: '/about/',
  },
  {
    label: 'online store',
    uri: 'https://ware.writtenbyby.com',
  },
  {
    label: 'story',
    uri: '/story/',
  },
  {
    label: 'news',
    uri: '/news/',
  },
];

export const Navigation: FC<Props> = ({ className }) => {
  const isMobile = useIsMobile();
  const { slug } = useSlug();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  useEffect(() => {
    setShowMobileMenu(false);
  }, [isMobile, slug]);
  const onClickMobileMenuButton = useCallback(() => {
    setShowMobileMenu((value) => !value);
  }, []);
  const menuListId = 'main-menu';
  return (
    <nav role="navigation" className={className || ''}>
      {isMobile && (
        <StyledMenuButton
          onClick={onClickMobileMenuButton}
          isPressed={showMobileMenu}
          targetId={menuListId}
        />
      )}
      {(!isMobile || (isMobile && showMobileMenu)) && (
        <StyledMenuList id={menuListId} menus={menus} />
      )}
    </nav>
  );
};

const StyledMenuButton = styled(MenuButton)`
  position: fixed;
  z-index: 11;
  top: ${({ theme }) => theme.structure.margin.top.sp}px;
  right: ${({ theme }) => theme.structure.margin.top.sp}px;
  display: none;
  ${media.lessThanIpadVertical`
    display: block;
  `}
`;

const StyledMenuList = styled(MenuList)`
  ${media.lessThanIpadVertical`
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: calc(60px + 33px);
  `}
`;

export default Navigation;
