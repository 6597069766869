import { createContext } from 'react';

export const initialValue = {
  slug: '/',
  isHome: true,
};

type SlugContextType = typeof initialValue;

export const SlugContext = createContext<SlugContextType>(initialValue);

export default SlugContext;
