import type { ComponentProps, FC } from 'react';
import styled from 'styled-components';

type Props = Omit<ComponentProps<'button'>, 'ref'>;
// NOTE:
// refでoverloadのerrorが出るので、
// omitする

export const Button: FC<Props> = ({
  className,
  onClick,
  children,
  disabled,
  type,
  ...rest
}) => (
  <Wrapper
    className={className || ''}
    onClick={onClick}
    disabled={disabled}
    type={type ?? 'button'}
    {...rest}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled.button`
  outline: none;
  border: 1px solid black;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
`;
