import type { FC } from 'react';
import styled from 'styled-components';

import { MenuListItem } from 'src/components/common';
import type { Props as MenuListItemProps } from 'src/components/common/menu-list-item';
import { media } from 'src/styles';

type Props = {
  id?: string;
  className?: string;
  menus: MenuListItemProps[];
};

export const MenuList: FC<Props> = ({ id, className, menus }) => (
  <Wrapper id={id || ''} className={className || ''}>
    {menus.map((menu, index) => (
      <MenuListItem key={`header-menu-${index}`} {...menu} />
    ))}
  </Wrapper>
);

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  ${media.lessThanIpadVertical`
    background-color: white;
    text-align: center;
  `}
  ${media.ipadVerticalOrMore`
    display: flex;
  `}
  & > li + li {
    ${media.lessThanIpadVertical`
      margin-top: 50px;
    `}
    ${media.ipadVerticalOrMore`
      margin-left: 2em;
    `}
  }
`;

export default MenuList;
