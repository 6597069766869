import { Link } from 'gatsby';
import type { FC } from 'react';
import styled from 'styled-components';

import { useLocation } from '@reach/router';
import { ScreenReaderText } from 'src/components/common';
import TitleSVG from 'src/images/logo.inline.svg';
import { media } from 'src/styles';

type Props = {
  className?: string;
};

export const Title: FC<Props> = ({ className }) => {
  const { pathname } = useLocation();
  return (
    <Wrapper className={className} as={pathname === '/' ? 'h1' : 'p'}>
      <Link to={'/'} lang="en">
        <ScreenReaderText>written after wards</ScreenReaderText>
        <TitleSVG />
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.h1`
  line-height: 1;
  width: auto;
  height: 18px;
  svg {
    width: auto;
    height: 100%;
  }
  ${media.lessThanIpadVertical`
    margin-left: auto;
    margin-right: auto;
  `}
`;

export default Title;
