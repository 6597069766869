import { graphql } from 'gatsby';
import type { FC } from 'react';
import styled from 'styled-components';

import { StyledGridImageItem } from 'src/components/common';
import { Img, StyledHoverImageAnchor } from 'src/components/common/atoms';
import { media, styleGaramond } from 'src/styles';

type Props = (
  | Queries.CollectionArchiveEntryFragment
  | Queries.ProductArchiveEntryFragment
) & {
  to: string;
};

export const query = graphql`
  fragment CollectionArchiveEntry on PrismicCollectionDataType {
    category {
      document {
        ... on PrismicCollectionCategory {
          data {
            title {
              text
            }
          }
        }
      }
    }
    title {
      text
    }
    images {
      image {
        gatsbyImageData
      }
    }
  }

  fragment ProductArchiveEntry on PrismicProductDataType {
    category {
      document {
        ... on PrismicProductCategory {
          data {
            title {
              text
            }
          }
        }
      }
    }
    title {
      text
    }
    images {
      image {
        gatsbyImageData
      }
    }
  }
`;

export const Entry: FC<Props> = ({ to, category, title, images }) => {
  const categoryTitle =
    category?.document &&
    'data' in category.document &&
    category.document.data.title;
  const firstImage = images?.at(0)?.image;
  return (
    <article>
      <StyledHoverImageAnchor to={to}>
        {(categoryTitle || title) && (
          <Title>
            <Category>{categoryTitle && categoryTitle.text}</Category>
            <TitleItalic>{title && title.text}</TitleItalic>
          </Title>
        )}
        {firstImage && (
          <StyledGridImageItem>
            <Img
              file={firstImage}
              sizes="(min-width: 1290px) 380px, (min-width: 768px) calc((100vw - 90px - 60px) / 3), calc((100vw - 44px - 12px) / 2)"
            />
          </StyledGridImageItem>
        )}
      </StyledHoverImageAnchor>
    </article>
  );
};

const Category = styled.span`
  display: block;
  min-height: calc(1em * 1.4);
`;

const TitleItalic = styled.i`
  display: block;
  font-style: italic;
`;

const Title = styled.h2`
  ${styleGaramond}
  line-height: 1.4;
  font-size: ${({ theme }) => theme.font.size.small.pc};
  text-align: center;
  margin-top: 8px;
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.large.pc};
    margin-top: 12px;
  `}
`;

export default Entry;
