import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import ITCGramondBookWoff from 'src/fonts/1575476/120422db-27fa-4ea0-b15c-8143059f8244.woff';
import ITCGramondBookWoff2 from 'src/fonts/1575476/3898fc2f-b3f8-4656-a283-bdcbf137ffbb.woff2';
import ITCGramondBookItalicWoff2 from 'src/fonts/1575480/c83f43b4-eeb9-4e38-8951-f553ce873be3.woff2';
import ITCGramondBookItalicWoff from 'src/fonts/1575480/dbf7f71d-2979-40c1-beda-f7b889ae9d0e.woff';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  ${reset}
  @font-face{
    font-family:"ITC Garamond W01 Book";
    font-display: swap;
    src:url(${ITCGramondBookWoff2}) format("woff2"),url(${ITCGramondBookWoff}) format("woff");
  }
  @font-face{
    font-family:"ITC Garamond W01 Book Italic";
    font-display: swap;
    src:url(${ITCGramondBookItalicWoff2}) format("woff2"),url(${ITCGramondBookItalicWoff}) format("woff");
  }
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
  ::selection {
    background: ${theme.colors.lightGray};
  }

  html {
    font-size: 62.5%;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: ${theme.font.family.main};
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;

    /* for react modal for mobile*/
    &.ReactModal__Body--open {
      overflow: hidden;
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }

  button,
  input,
  select,
  textarea {
    appearance: none;
    border-radius: 0;
    background-color: ${theme.colors.bg};
    border: 1px solid ${theme.colors.font};
    line-height: 1.6;
    font-family: inherit;
    font-size: inherit;
  }

  select::-ms-expand {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover,
    &.focus-visible {
      text-decoration: none;
    }
  }

  a,
  button,
  input,
  select,
  textarea {
    &.focus-visible {
      outline: 1px solid black;
    }
    &:focus-visible {
      outline: 1px solid black;
    }
  }

  img,
  svg {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  ul, ol {
    list-style: initial;
    padding-left: 1em;
  }

  ol {
    list-style: auto;
  }
`;

export default GlobalStyle;
