import type { FC, ReactNode } from 'react';
import { media } from 'src/styles';
import styled from 'styled-components';

type Props = {
  className?: string;
  children: ReactNode;
  isLargeMargin?: boolean;
};

export const PageHeader: FC<Props> = ({
  className,
  children,
  isLargeMargin = false,
}) => (
  <Wrapper
    className={`${className ?? ''} ${isLargeMargin ? 'is-large-margin' : ''}`}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  ${media.lessThanIpadVertical`
    padding-bottom: 25px;
    &.is-large-margin {
      padding-bottom: 50px;
    }
  `}
`;

export default PageHeader;
