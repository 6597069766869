exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-foreword-tsx": () => import("./../../../src/pages/foreword.tsx" /* webpackChunkName: "component---src-pages-foreword-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-stockist-tsx": () => import("./../../../src/pages/stockist.tsx" /* webpackChunkName: "component---src-pages-stockist-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-collections-tsx": () => import("./../../../src/templates/collections.tsx" /* webpackChunkName: "component---src-templates-collections-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-products-brand-tsx": () => import("./../../../src/templates/products-brand.tsx" /* webpackChunkName: "component---src-templates-products-brand-tsx" */),
  "component---src-templates-stories-tsx": () => import("./../../../src/templates/stories.tsx" /* webpackChunkName: "component---src-templates-stories-tsx" */),
  "component---src-templates-stories-year-archive-tsx": () => import("./../../../src/templates/stories-year-archive.tsx" /* webpackChunkName: "component---src-templates-stories-year-archive-tsx" */),
  "component---src-templates-story-tsx": () => import("./../../../src/templates/story.tsx" /* webpackChunkName: "component---src-templates-story-tsx" */)
}

