import { graphql, useStaticQuery } from 'gatsby';
import { type ComponentProps } from 'react';

import { useSlug } from 'src/utils';

type MetaProps = ComponentProps<'meta'>;
type Props = {
  title?: string;
  description?: string | null;
  meta?: MetaProps[];
  image?: string;
  isSingle?: boolean;
  firstPublicationDate?: string;
  lastPublicationDate?: string;
};

const query = graphql`
  query GetSeo {
    site {
      siteMetadata {
        title
        titleAlt
        siteLanguage
        description
        author
        siteUrl
        twitter
        facebookAppId
        banner
      }
    }
  }
`;

export const SEO: React.FC<Props> = ({
  title,
  description,
  meta,
  image,
  isSingle = false,
  firstPublicationDate,
  lastPublicationDate,
}) => {
  const { slug } = useSlug();
  const { site } = useStaticQuery<Queries.GetSeoQuery>(query);
  const defaultTitle = site?.siteMetadata?.title || '';
  const pageTitle = title ? `${title} - ${defaultTitle}` : defaultTitle || '';
  const defaultDescription = site?.siteMetadata?.description;
  const metaDescription =
    description?.replace(/\r?\n/g, ' ')?.substr(0, 160) ||
    defaultDescription ||
    '';
  const siteUrl = site?.siteMetadata?.siteUrl || '';
  const url = `${siteUrl}${slug}`;
  const banner = site?.siteMetadata?.banner || '';
  const seoImage = image ? `${siteUrl}${image}` : `${siteUrl}/${banner}`;

  const defaultMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      property: `og:title`,
      content: pageTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: seoImage,
    },
    {
      property: `fb:app_id`,
      content: site?.siteMetadata?.facebookAppId,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: site?.siteMetadata?.author,
    },
    {
      name: `twitter:title`,
      content: pageTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: seoImage,
    },
  ] as MetaProps[];

  const pageMeta = meta ? [...defaultMeta, ...meta] : defaultMeta;

  let schemaOrgJSONLD: { [key: string]: any }[] = [
    {
      '@context': 'http://schema.org',
      '@type': 'LocalBusiness',
      '@id': siteUrl,
      url: siteUrl,
      name: defaultTitle,
      image: {
        '@type': 'ImageObject',
        url: `${siteUrl}/${banner}`,
      },
      alternateName: site?.siteMetadata?.titleAlt || '',
    },
  ];

  if (isSingle) {
    const author = site?.siteMetadata?.author || '@';
    schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        '@id': url,
        url: url,
        name: title ?? '',
        alternateName: site?.siteMetadata?.titleAlt || '',
        headline: title ?? '',
        image: {
          '@type': 'ImageObject',
          url: seoImage,
        },
        description: description,
        datePublished: firstPublicationDate || undefined,
        dateModified: lastPublicationDate || undefined,
        author: {
          '@type': 'Person',
          name: author,
        },
        publisher: {
          '@type': 'Organization',
          name: author,
          logo: {
            '@type': 'ImageObject',
            url: `${siteUrl}/icons/icon-512x512.png`,
          },
        },
        isPartOf: siteUrl,
        mainEntityOfPage: {
          '@type': 'LocalBusiness',
          '@id': siteUrl,
        },
      },
    ];
  }

  return (
    <>
      <html lang={site?.siteMetadata?.siteLanguage ?? 'ja'} />
      <title>{pageTitle}</title>
      {pageMeta.map((item, index) => {
        if ('name' in item) {
          return <meta key={index} name={item.name} content={item.content} />;
        } else if ('property' in item) {
          return (
            <meta key={index} property={item.property} content={item.content} />
          );
        }
      })}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </>
  );
};

export default SEO;
