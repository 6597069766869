import { graphql } from 'gatsby';
import { useMemo, type FC } from 'react';

import { SlideImage } from 'src/components/common';
import { useIsMobile } from 'src/utils';

type Props = {
  className?: string;
  items:
    | Queries.CollectionSingleBodyImageFragment[]
    | Queries.ProductSingleBodyImageFragment[];
};

export const fragment = graphql`
  fragment CollectionSingleBodyImage on PrismicCollectionDataBodySubContentItem {
    sub_image {
      alt
      gatsbyImageData
    }
  }

  fragment ProductSingleBodyImage on PrismicProductDataBodySubContentItem {
    sub_image {
      alt
      gatsbyImageData
    }
  }
`;

export const Images: FC<Props> = ({ className, items }) => {
  const isMobile = useIsMobile();
  const images = useMemo(
    () =>
      items.map(({ sub_image }) => ({
        image: sub_image,
      })),
    [items]
  );
  return <SlideImage images={images} maxHeight={!isMobile ? 550 : undefined} />;
};

export default Images;
