import { Link, type GatsbyLinkProps } from 'gatsby';
import type { ComponentProps, FC } from 'react';
import styled from 'styled-components';

type Props = ComponentProps<'a'> &
  GatsbyLinkProps<Record<string, never>> & {
    className?: string;
    children: React.ReactNode;
  };

export const Anchor: FC<Props> = ({
  className,
  to,
  children,
  ['aria-label']: ariaLabel,
  activeClassName,
  partiallyActive = false,
}) => {
  const isInternal = to?.startsWith('/');
  if (isInternal) {
    return (
      <StyledLink
        className={className || ''}
        to={to}
        aria-label={ariaLabel || ''}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
      >
        {children}
      </StyledLink>
    );
  } else {
    return (
      <a
        className={className || ''}
        href={to}
        target={!to.startsWith('mailto') ? '_blank' : undefined}
        rel="noopener noreferrer"
        aria-label={ariaLabel || ''}
      >
        {children}
      </a>
    );
  }
};

const StyledLink = styled(Link)`
  &:focus {
    outline: none;
  }
`;

export default Anchor;
