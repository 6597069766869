import styled from 'styled-components';

export const StyledGridImageItem = styled.div`
  position: relative;
  aspect-ratio: 3 / 4;
  @supports not (aspect-ratio: 3 / 4) {
    padding-top: calc(4 / 3 * 100%);
  }
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    > div:fist-of-type {
      display: none;
    }
  }
`;
