import type { FC } from 'react';
import type { Props as SpringProps } from './spring';
import { Spring } from './spring';

export type Props = {
  className?: string;
  images: SpringProps['images'];
  initialIndex?: number;
  maxHeight?: number;
  hasArrowButton?: boolean;
  inModal?: boolean;
};

export const SlideImage: FC<Props> = ({
  className,
  images,
  initialIndex = 0,
  maxHeight,
  hasArrowButton,
  inModal,
}) => (
  <div className={className || ''}>
    <Spring
      initialIndex={initialIndex}
      maxHeight={maxHeight}
      images={images}
      hasArrowButton={hasArrowButton}
      inModal={inModal}
    />
  </div>
);

export default SlideImage;
