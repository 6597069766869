import type { FC } from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  className?: string;
};

export const Loader: FC<Props> = ({ className }) => (
  <Wrapper className={className || ''}></Wrapper>
);

// const loadingAnimation = keyframes`
//   0% {
//     left: 0;
//     width: 0;
//   }
//   20% {
//     left: 0;
//     width: 100%;
//   }
//   80% {
//     left: auto;
//     right: 0;
//     width: 100%;
//     transform-origin: center right;
//     transform: scaleX(1);
//   }
//   100% {
//     left: auto;
//     right: 0;
//     width: 100%;
//     transform-origin: center right;
//     transform: scaleX(0);
//   }
// `;

const loadingAnimation = keyframes`
  0% {
    left: 45%;
    width: 0;
  }
  20% {
    left: 45%;
    width: 10%;
  }
  80% {
    left: 45%;
    right: 45%;
    width: 10%;
    transform-origin: center right;
    transform: scaleX(1);
  }
  100% {
    left: 45%;
    right: 45%;
    width: 10%;
    transform-origin: center right;
    transform: scaleX(0);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 0;
  height: 1px;
  background-color: black;
  animation: ${loadingAnimation} 1.8s infinite cubic-bezier(0.87, 0, 0.13, 1);
`;

export default Loader;
