import { graphql } from 'gatsby';
import type { FC } from 'react';
import styled from 'styled-components';

import { Video } from './video';

type Props = {
  className?: string;
  items:
    | Queries.CollectionSingleBodyVideoFragment[]
    | Queries.ProductSingleBodyVideoFragment[];
};

export const fragment = graphql`
  fragment CollectionSingleBodyVideo on PrismicCollectionDataBodySubContentVideoItem {
    sub_video {
      html
    }
  }
  fragment ProductSingleBodyVideo on PrismicProductDataBodySubContentVideoItem {
    sub_video {
      html
    }
  }
`;

export const Videos: FC<Props> = ({ className, items }) => (
  <div className={className || ''}>
    {items.map(
      ({ sub_video }, index) =>
        sub_video?.html && (
          <StyledVideo
            key={`collection-body-video-${index}`}
            html={sub_video.html}
          />
        )
    )}
  </div>
);

const StyledVideo = styled(Video)`
  & + & {
    margin-top: 60px;
  }
`;

export default Videos;
