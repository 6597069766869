import { animated, useSpring, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import type { ImageDataLike } from 'gatsby-plugin-image';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type FC,
  type KeyboardEventHandler,
  type RefObject,
  type SetStateAction,
} from 'react';
import useMeasure from 'react-use-measure';

import { ArrowButton } from 'src/components/common/arrow-button';
import { Img } from 'src/components/common/atoms';
import { media, theme } from 'src/styles';
import { useIsMobile } from 'src/utils';
import styled, { css } from 'styled-components';

export type Props = {
  className?: string;
  initialIndex: number;
  maxHeight?: number;
  images: ReadonlyArray<{
    image: ({ alt: string | null } & ImageDataLike) | null;
  } | null>;
  hasArrowButton?: boolean;
  inModal?: boolean;
};

const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

type UseImageSpringProps = Pick<Props, 'images' | 'initialIndex'> & {
  wrapperWidth: number;
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
};
const useImageSpring = ({
  images,
  initialIndex,
  wrapperWidth,
  currentIndex,
  setCurrentIndex,
}: UseImageSpringProps) => {
  const isMobile = useIsMobile();
  const [imageSpringProps, imageSpringApi] = useSprings(
    images.length,
    (i) => ({
      x: (i - initialIndex) * wrapperWidth,
      scale: 1,
      display: 'block',
    }),
    [wrapperWidth]
  );

  const imageBind = useDrag(
    ({
      active,
      movement: [mx],
      direction: [xDir],
      distance: [xDis],
      cancel,
    }) => {
      const threshold = isMobile ? 30 : 80;
      if (active && (xDir > 0.5 || xDir < -0.5) && xDis > threshold) {
        setCurrentIndex(
          clamp(currentIndex + (xDir > 0 ? -1 : 1), 0, images.length - 1)
        );
        cancel();
      }
      imageSpringApi.start((i: number) => {
        if (i < currentIndex - 1 || i > currentIndex + 1) {
          return { display: 'none' };
        }
        const x = (i - currentIndex) * wrapperWidth + (active ? mx : 0);
        const scale = active ? 1 - xDis / wrapperWidth / 8 : 1;
        return { x, scale, display: 'block' };
      });
    },
    { axis: 'x' }
  );

  // update index
  useEffect(() => {
    imageSpringApi.start((i: number) => {
      if (i < currentIndex - 1 || i > currentIndex + 1) {
        return { display: undefined };
      }
      const x = (i - currentIndex) * wrapperWidth;
      return { x, display: 'block' };
    });
  }, [imageSpringApi, images, currentIndex, wrapperWidth]);

  return {
    imageSpringProps,
    imageBind,
  };
};

type UseImageHeightProps = Pick<Props, 'maxHeight'> & {
  rootRef: RefObject<HTMLDivElement>;
  wrapperWidth: number;
  currentIndex: number;
};
const useImageHeight = ({
  rootRef,
  maxHeight = 0,
  wrapperWidth,
  currentIndex,
}: UseImageHeightProps) => {
  // image initial height
  const [imageWrapperHeight, setImageWrapperHeight] = useState(maxHeight);
  useEffect(() => {
    // heightが取得できないことがあるので、
    // timerをセット
    const timerId = setTimeout(() => {
      if (rootRef.current) {
        const currentGatsbyImage =
          rootRef.current.querySelector<HTMLDivElement>('.js--current');
        if (currentGatsbyImage) {
          const ratio =
            currentGatsbyImage.offsetHeight / currentGatsbyImage.offsetWidth;
          const distHeight = wrapperWidth * ratio;
          const height =
            maxHeight !== 0 && maxHeight !== undefined
              ? clamp(distHeight, 0, maxHeight)
              : distHeight;
          setImageWrapperHeight(height);
        }
      }
    }, 600);
    return () => {
      clearTimeout(timerId);
    };
  }, [rootRef, wrapperWidth, maxHeight, currentIndex]);
  return { imageWrapperHeight };
};

type UseControllerSpringProps = Pick<Props, 'images' | 'inModal'> & {
  wrapperWidth: number;
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
};
const useControllerSpring = ({
  images,
  inModal,
  wrapperWidth,
  currentIndex,
  setCurrentIndex,
}: UseControllerSpringProps) => {
  const isMobile = useIsMobile();
  const trackWidth = isMobile && inModal ? wrapperWidth - 30 : wrapperWidth;
  const [controllerSpringProp, controllerSpringApi] = useSpring(() => {
    const barWidth = trackWidth / images.length;
    return {
      width: barWidth,
      x: barWidth * currentIndex,
    };
  }, [trackWidth]);

  const controllerBind = useDrag(
    ({
      active,
      movement: [mx],
      direction: [xDir],
      distance: [xDis],
      cancel,
    }) => {
      const barWidth = trackWidth / images.length;
      if (active && xDis > barWidth / 2) {
        setCurrentIndex(
          clamp(currentIndex + (xDir > 0 ? 1 : -1), 0, images.length - 1)
        );
        cancel();
      }
      const x = currentIndex * barWidth + (active ? mx : 0);
      controllerSpringApi.start({ x });
    },
    { axis: 'x' }
  );

  // update index
  useEffect(() => {
    const x = currentIndex * (trackWidth / images.length);
    controllerSpringApi.start({ x });
  }, [images, currentIndex, trackWidth, controllerSpringApi]);

  return { controllerBind, controllerSpringProp };
};

export const Spring: FC<Props> = ({
  className,
  initialIndex,
  maxHeight = 0,
  images,
  hasArrowButton = false,
  inModal = false,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [wrapperRef, { width: wrapperWidth }] = useMeasure();
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  // image spring
  const { imageBind, imageSpringProps } = useImageSpring({
    images,
    initialIndex,
    wrapperWidth,
    currentIndex,
    setCurrentIndex,
  });

  const { imageWrapperHeight } = useImageHeight({
    rootRef,
    maxHeight,
    wrapperWidth,
    currentIndex,
  });
  const { controllerBind, controllerSpringProp } = useControllerSpring({
    images,
    inModal,
    wrapperWidth,
    currentIndex,
    setCurrentIndex,
  });

  const goNext = useCallback(() => {
    setCurrentIndex((value) => clamp(value + 1, 0, images.length - 1));
  }, [images]);

  const goPrev = useCallback(() => {
    setCurrentIndex((value) => clamp(value - 1, 0, images.length - 1));
  }, [images]);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      if (event.key === 'ArrowLeft') {
        // arrow left
        goPrev();
      } else if (event.key === 'ArrowRight') {
        // arrow right
        goNext();
      }
    },
    [goPrev, goNext]
  );

  return (
    <Wrapper
      className={inModal && imageWrapperHeight === 0 ? 'hide' : ''}
      onKeyDown={onKeyDown}
      tabIndex={0}
      ref={rootRef}
    >
      {hasArrowButton && (
        <>
          <NextButton
            direction="right"
            onClick={goNext}
            color={theme.colors.gray}
            disabled={currentIndex === images.length - 1}
            ariaLabel={'Go to the next picture'}
          />
          <PrevButton
            direction="left"
            onClick={goPrev}
            color={theme.colors.gray}
            disabled={currentIndex === 0}
            ariaLabel={'Go to the previous picture'}
          />
        </>
      )}
      <ImageWrapper
        className={className || ''}
        height={imageWrapperHeight}
        ref={wrapperRef}
      >
        {imageSpringProps.map(({ x, display, scale }, index) => (
          <animated.div
            {...imageBind()}
            key={`slide-image-${index}`}
            style={{
              display,
              x,
              height: maxHeight ? '100%' : '',
              touchAction: 'pan-y',
            }}
            aria-hidden={index !== currentIndex ? true : undefined}
          >
            <animated.div
              style={{
                scale,
                height: maxHeight ? '100%' : '',
              }}
            >
              <StyledImg
                className={`${maxHeight ? 'has-max-height' : ''} ${
                  index === currentIndex ? 'js--current' : ''
                }`}
                file={images?.[index]?.image}
                alt={images?.[index]?.image?.alt ?? `image ${index}`}
                aria-hidden={index !== currentIndex ? true : undefined}
                loading={
                  index === currentIndex || index === currentIndex + 1
                    ? 'eager'
                    : 'lazy'
                }
              />
            </animated.div>
          </animated.div>
        ))}
      </ImageWrapper>
      {imageWrapperHeight > 0 && (
        <Controller className={inModal ? 'in-modal' : ''} aria-hidden={true}>
          <animated.div
            className={'control-bar'}
            {...controllerBind()}
            style={controllerSpringProp}
          />
        </Controller>
      )}
    </Wrapper>
  );
};

const ButtonBaseStyle = css`
  position: absolute;
  z-index: 1;
  button {
    &.focus-visible {
      outline: solid red 1px;
    }
  }
  ${media.lessThanIpadVertical`
    bottom: 23px;
  `}
  ${media.ipadVerticalOrMore`
    top: 48%;
  `}
`;

const NextButton = styled(ArrowButton)`
  ${ButtonBaseStyle}
  ${media.lessThanIpadVertical`
    right: 15px;
  `}
  ${media.ipadVerticalOrMore`
    right: -30px;
    transform: translateX(100%);
  `}
`;

const PrevButton = styled(ArrowButton)`
  ${ButtonBaseStyle}
  ${media.lessThanIpadVertical`
    left: 15px;
  `}
  ${media.ipadVerticalOrMore`
    left: -30px;
    transform: translateX(-100%);
  `}
`;

const StyledImg = styled(Img)`
  pointer-events: none;
  width: 100%;
  &.vertical-image {
    &.has-max-height {
      height: 100%;
      > .gatsby-image-wrapper {
        height: 100%;
        > div:first-of-type {
          display: none !important;
        }
      }
      img {
        object-fit: contain !important;
      }
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
  }
`;

type ImageWrapperProps = {
  height: number;
};
const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  width: 100%;
  height: ${(props: ImageWrapperProps) => props.height}px;
  overflow: hidden;
  transition: height 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  will-change: height;
  > div {
    position: absolute;
    width: 100%;
    will-change: transform;
    cursor: -webkit-grab;
    &:active {
      cursor: -webkit-grabbing;
    }
    > div {
      width: 100%;
      will-change: transform;
    }
  }
`;

const Controller = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.gray};
  margin-top: 60px;
  .control-bar {
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    height: 100%;
    cursor: -webkit-grab;
    &:active {
      cursor: -webkit-grabbing;
    }
  }
  ${media.lessThanIpadVertical`
    &.in-modal {
      width: calc(100% - 30px);
      margin-left: auto;
      margin-right: auto;
    }
  `}
  ${media.ipadVerticalOrMore`
    height: 3px;
  `}
`;

const Wrapper = styled.div`
  position: relative;
  transition: opacity 0.5s linear;
  &.hide {
    opacity: 0;
  }
`;

export default Spring;
