import {
  GatsbyImage,
  getImage,
  withArtDirection,
  type GatsbyImageProps,
  type IArtDirectedImage,
  type ImageDataLike,
} from 'gatsby-plugin-image';
import { useCallback, useMemo, useRef, type FC } from 'react';
import styled from 'styled-components';

type Props = Omit<GatsbyImageProps, 'image' | 'alt'> & {
  className?: string;
  file?: ImageDataLike | null;
  artDirections?: {
    media: string;
    file: ImageDataLike | null;
  }[];
  alt?: string | null;
  loading?: 'lazy' | 'eager';
  fadeInDuration?: number;
  onLoad?(image: HTMLImageElement | null): void;
};

const useImage = ({
  file,
  artDirections,
}: Pick<Props, 'file' | 'artDirections'>) =>
  useMemo(() => {
    if (file) {
      if (!artDirections) {
        return getImage(file);
      } else {
        const defaultImage = getImage(file);
        if (defaultImage) {
          return withArtDirection(
            defaultImage,
            artDirections
              .map((artDirection) => ({
                media: artDirection.media,
                image: getImage(artDirection.file),
              }))
              .filter(
                (artDirection) => artDirection.image
              ) as IArtDirectedImage[]
          );
        }
      }
    }
    return null;
  }, [file, artDirections]);

export const Img: FC<Props> = ({
  className,
  file,
  artDirections,
  alt,
  loading = 'lazy',
  onLoad,
  fadeInDuration,
  ...rest
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const handleLoaded = useCallback(() => {
    let image: HTMLImageElement | null = null;
    if (wrapperRef.current) {
      image = wrapperRef.current.querySelector('img');
    }
    if (onLoad) {
      onLoad(image);
    }
  }, [onLoad]);

  const image = useImage({ file, artDirections });
  if (!image) {
    return null;
  }
  // TODO:
  // aspectRatioはgraphqlから直接取得するように変更する。

  const aspectRatio = image?.width / image?.height;
  const aspectRatioClass = aspectRatio
    ? aspectRatio > 1
      ? 'horizontal-image'
      : 'vertical-image'
    : '';

  return (
    <Wrapper
      className={`${className || ''} ${aspectRatioClass}`}
      ref={wrapperRef}
      fadeInDuration={fadeInDuration}
    >
      <GatsbyImage
        image={image}
        alt={alt ?? ''}
        onLoad={handleLoaded}
        loading={loading}
        {...rest}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ fadeInDuration: Props['fadeInDuration'] }>`
  .gatsby-image-wrapper [data-main-image] {
    ${({ fadeInDuration }) =>
      fadeInDuration
        ? `transition-duration: ${fadeInDuration}ms !important;`
        : null};
  }
`;

export default Img;
