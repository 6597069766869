import { graphql } from 'gatsby';
import { useContext, useEffect, type FC } from 'react';

import { Grid, ViewMoreButton } from 'src/components/common';
import {
  ArchiveContext,
  ArchiveDispatchContext,
} from 'src/contexts/archive-context';
import { useFetchNextPage } from 'src/utils';
import { Entry } from './entry';

type ArchiveEntriesFragmentType =
  | Queries.CollectionArchiveEntriesFragment
  | Queries.ProductArchiveEntriesFragment;

type Props = ArchiveEntriesFragmentType & {
  className?: string;
  archivePath: string;
};

export const fragment = graphql`
  fragment CollectionArchiveEntries on PrismicCollectionConnection {
    totalCount
    nodes {
      id
      uid
      data {
        ...CollectionArchiveEntry
      }
    }
    pageInfo {
      hasNextPage
    }
  }

  fragment ProductArchiveEntries on PrismicProductConnection {
    totalCount
    nodes {
      id
      uid
      data {
        ...ProductArchiveEntry
      }
    }
    pageInfo {
      hasNextPage
    }
  }
`;

export const Entries: FC<Props> = ({
  className,
  archivePath,
  totalCount,
  nodes,
  pageInfo: { hasNextPage },
}) => {
  const { dispatch } = useContext(ArchiveDispatchContext);
  const context = useContext(ArchiveContext);
  const archiveNodes = context[
    archivePath
  ] as ArchiveEntriesFragmentType['nodes'];
  useEffect(() => {
    if (dispatch && !archiveNodes) {
      dispatch({
        type: 'set',
        payload: {
          [archivePath]: nodes,
        },
      });
    }
  }, [archiveNodes, archivePath, dispatch, nodes]);

  const { fetchNextPage, isFetching } = useFetchNextPage<Props['nodes']>({
    archivePath,
    nodes: archiveNodes,
  });
  const isAllFetched = totalCount === archiveNodes?.length;
  return (
    <div className={className || ''}>
      {archiveNodes && (
        <Grid hasTitle={true}>
          {archiveNodes?.map((node) => {
            if (!node?.data) {
              return null;
            }
            return (
              <Entry
                key={node.id}
                to={`/${archivePath}/${node.uid}/`}
                {...node.data}
              />
            );
          })}
        </Grid>
      )}
      {hasNextPage && !isAllFetched && (
        <ViewMoreButton onClick={fetchNextPage} isFetching={isFetching} />
      )}
    </div>
  );
};

export default Entries;
