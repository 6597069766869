import type { FC } from 'react';
import styled from 'styled-components';

import { media, styleGaramond } from 'src/styles';
import { Navigation } from './navigation';

type Props = {
  className?: string;
};

export const Footer: FC<Props> = ({ className }) => (
  <footer role="contentinfo" className={className || ''}>
    <Navigation />
    <Copyright>writtenafterwards inc. all right reserved.</Copyright>
  </footer>
);

const Copyright = styled.p`
  ${styleGaramond}
  line-height: 1;
  ${media.lessThanIpadVertical`
    text-align: center;
    margin-top: 45px;
    font-size: ${({ theme }) => theme.font.size.small.sp};
  `}
  ${media.ipadVerticalOrMore`
    margin-top: 60px;
    font-size: ${({ theme }) => theme.font.size.small.pc};
  `}
`;

export default Footer;
