import type { FC } from 'react';
import styled from 'styled-components';

import { Anchor } from 'src/components/common/atoms';
import { media, styleGaramond } from 'src/styles';

export type Props = {
  className?: string;
  uri: string;
  label: string;
  isPartiallyActive?: boolean;
};

export const MenuListItem: FC<Props> = ({
  className,
  uri,
  label,
  isPartiallyActive = true,
}) => (
  <Wrapper className={className || ''}>
    <StyledAnchor
      to={uri}
      activeClassName={'active'}
      partiallyActive={isPartiallyActive}
    >
      {label}
    </StyledAnchor>
  </Wrapper>
);

const StyledAnchor = styled(Anchor)`
  line-height: 1;
  text-decoration: none;
  display: inline-block;
  --text-underline-offset: 6px;
  &:focus {
    outline: none;
  }
  &.active,
  &.focus-visible {
    text-decoration: underline;
    text-underline-offset: var(--text-underline-offset);
  }
  &:focus-visible {
    text-decoration: underline;
    text-underline-offset: var(--text-underline-offset);
  }
  ${media.ipadVerticalOrMore`
    --text-underline-offset: 13px;
  `}
`;

const Wrapper = styled.li`
  ${styleGaramond}
  ${media.lessThanIpadVertical`
    font-size: ${({ theme }) => theme.font.size.large.sp};
  `}
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.normal.pc};
  `}
`;

export default MenuListItem;
