import type { FC } from 'react';
import styled from 'styled-components';

import { Anchor } from 'src/components/common/atoms';
import { media, styleGaramond } from 'src/styles';

type Props = Record<string, never>;

const menus = [
  {
    label: 'contact',
    uri: 'mailto:contact@writtenafterwards.com',
  },
  {
    label: 'stockist',
    uri: '/stockist/',
  },
  {
    label: 'instagram',
    uri: 'https://www.instagram.com/writtenafterwards/',
  },
];

export const Navigation: FC<Props> = () => (
  <MenuList>
    {menus.map((menu, index) => (
      <MenuListItem key={`footer-menu-${index}`}>
        <StyledAnchor to={menu.uri} activeClassName={'active'}>
          {menu.label}
        </StyledAnchor>
      </MenuListItem>
    ))}
  </MenuList>
);

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  &:focus {
    outline: none;
  }
  &.active,
  &.focus-visible {
    text-decoration: underline;
  }
  &:focus-visible {
    text-decoration: underline;
  }
`;

const MenuListItem = styled.li`
  line-height: 1;
  font-size: ${({ theme }) => theme.font.size.small.sp};
  & + & {
    margin-left: 2em;
  }
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.normal.pc};
  `}
`;

const MenuList = styled.ul`
  ${styleGaramond}
  display: flex;
  list-style: none;
  padding: 0;
  ${media.lessThanIpadVertical`
    justify-content: center;
  `}
`;

export default Navigation;
