import type { FC } from 'react';
import styled from 'styled-components';

import { Button } from 'src/components/common/atoms';
import { media } from 'src/styles';

type Props = {
  className?: string;
  onClick(): void;
  isFetching?: boolean;
};

export const ViewMoreButton: FC<Props> = ({
  className,
  onClick,
  isFetching = false,
}) => (
  <Wrapper className={className || ''}>
    <StyledViewMoreButton onClick={onClick} disabled={isFetching}>
      {!isFetching && 'view more'}
      {isFetching && 'loading'}
    </StyledViewMoreButton>
  </Wrapper>
);

const Wrapper = styled.div`
  padding-top: 30px;
  text-align: center;
  ${media.ipadVerticalOrMore`
    padding-top: 60px;
  `}
`;
const StyledViewMoreButton = styled(Button)`
  border: none;
  display: inline-block;
  &:hover {
    opacity: 0.5;
  }
`;

export default ViewMoreButton;
