import type { FC } from 'react';
import styled from 'styled-components';

import { media } from 'src/styles';

type Props = {
  className?: string;
  children: React.ReactNode;
  hasTitle?: boolean;
  itemSize?: 'm' | 'l';
};

export const Grid: FC<Props> = ({
  className,
  children,
  hasTitle = false,
  itemSize = 'm',
}) => (
  <Wrapper
    className={`${className || ''} ${
      hasTitle ? 'has-title' : ''
    } item-size-${itemSize}`}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.structure.margin.grid.sp}px;
  &.has-title {
    row-gap: 22px;
  }
  &.item-size-m {
    grid-template-columns: repeat(2, 1fr);
  }
  &.item-size-l {
    grid-template-columns: repeat(1, 1fr);
  }
  ${media.ipadVerticalOrMore`
    gap: ${({ theme }) => theme.structure.margin.grid.pc}px;
    &.has-title {
      row-gap: 60px;
    }
    &.item-size-m {
      grid-template-columns: repeat(3, 1fr);
    }
    &.item-size-l {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

export default Grid;
