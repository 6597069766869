import 'focus-visible';
import { PageProps } from 'gatsby';
import type { FC } from 'react';
import styled from 'styled-components';

import { Drawing, Footer, Header, ProviderManager } from '../components/common';
import { GlobalStyle, media, theme } from '../styles';

type Props = PageProps;

export const Layouts: FC<Props> = ({ children, location }) => {
  // NOTE:
  // gatsby-plugin-offlineを使用すると、
  // swインストール後、一旦fallbackページを経由する場合があり、
  // その際hydrationエラーが発生するため
  // fallbackページの場合はnullを返す
  // エラー内容
  // https://reactjs.org/docs/error-decoder.html/?invariant=418
  // https://reactjs.org/docs/error-decoder.html/?invariant=423
  if (location.pathname === '/offline-plugin-app-shell-fallback/') {
    return null;
  }
  return (
    <>
      <GlobalStyle />
      <ProviderManager slug={location.pathname}>
        <Page>
          <Header />
          <Main role="main">
            <Height100>{children}</Height100>
          </Main>
          <StyledFooter />
        </Page>
        <StyledDrawing />
      </ProviderManager>
    </>
  );
};

const Height100 = styled.div`
  /* NOTE:
  flex-growで伸びたheightはchild側で取れないので
  一度display:flexをかます。
  flex-directionをcolumnにするとまた取得できなくなるので、
  rowのままにして、childを一つだけ（Height100）間にかます。
  */
  width: 100%;
`;

const Main = styled.main`
  font-size: ${({ theme }) => theme.font.size.normal.sp};
  line-height: ${({ theme }) => theme.lineHeight.sp};
  flex-grow: 1;
  margin-top: 60px;
  /* NOTE:
  flex-growで伸びたheightはchild側で取れないので
  一度display:flexをかます。
  flex-directionをcolumnにするとまた取得できなくなるので、
  rowのままにして、childを一つだけ（Height100）間にかます。
  */
  display: flex;
  height: 100%;
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.normal.pc};
    line-height: ${({ theme }) => theme.lineHeight.pc};
    margin-top: 120px;
  `}
`;

const StyledFooter = styled(Footer)`
  margin-top: 60px;
  ${media.ipadVerticalOrMore`
    margin-top: 120px;
  `}
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-left: ${theme.structure.margin.horizontal.sp}px;
  padding-right: ${theme.structure.margin.horizontal.sp}px;
  padding-top: ${theme.structure.margin.top.sp}px;
  padding-bottom: ${theme.structure.margin.bottom.sp}px;
  ${media.ipadVerticalOrMore`
    max-width: ${
      theme.breakpoints.large + theme.structure.margin.horizontal.pc * 2
    }px;
    margin-left: auto;
    margin-right: auto;
    padding-left: ${theme.structure.margin.horizontal.pc}px;
    padding-right: ${theme.structure.margin.horizontal.pc}px;
    padding-top: ${theme.structure.margin.top.pc}px;
    padding-bottom: ${theme.structure.margin.bottom.pc}px;
  `}
`;

const StyledDrawing = styled(Drawing)`
  position: absolute;
  z-index: 100;
  /* z-index: -1; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default Layouts;
