import { useReducer, type FC, type ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import {
  ArchiveContext,
  ArchiveDispatchContext,
  ArchiveReducer,
  initialValue as archiveInitialValue,
} from 'src/contexts/archive-context';
import { SlugContext } from 'src/contexts/slug-context';
import { theme as styleTheme } from 'src/styles';

type Props = {
  slug: string;
  children: ReactNode;
};

export const ProviderManager: FC<Props> = ({ slug, children }) => {
  const isHome = slug === '/';
  const [archive, dispatchArchive] = useReducer(
    ArchiveReducer,
    archiveInitialValue
  );
  return (
    <ThemeProvider theme={styleTheme}>
      <SlugContext.Provider value={{ slug, isHome }}>
        <ArchiveContext.Provider value={archive}>
          <ArchiveDispatchContext.Provider
            value={{ dispatch: dispatchArchive }}
          >
            {children}
          </ArchiveDispatchContext.Provider>
        </ArchiveContext.Provider>
      </SlugContext.Provider>
    </ThemeProvider>
  );
};

export default ProviderManager;
