import type { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { ScreenReaderText } from 'src/components/common';
import { media, styleGaramond } from 'src/styles';
import { useIsMobile } from 'src/utils';

type Props = {
  className?: string;
  isSingle?: boolean;
  children: ReactNode;
};

export const PageTitle: FC<Props> = ({
  className,
  isSingle = false,
  children,
}) => {
  const isMobile = useIsMobile();
  return (
    <Wrapper className={className || ''} as={isSingle ? 'p' : 'h1'}>
      {isMobile && <Span>{children}</Span>}
      {!isMobile && <ScreenReaderText>{children}</ScreenReaderText>}
    </Wrapper>
  );
};

const Span = styled.span`
  position: relative;
  display: inline-block;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    border-bottom: 1px solid black;
  }
  ${media.ipadVerticalOrMore`
    display: none;
  `}
`;

const Wrapper = styled.h1`
  ${styleGaramond}
  line-height: 1;
  text-transform: lowercase;
  font-size: ${({ theme }) => theme.font.size.large.sp};
  ${media.lessThanIpadVertical`
    text-align: center;
  `}
`;

export default PageTitle;
