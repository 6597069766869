import { useEffect, useState, type FC } from 'react';
import styled from 'styled-components';

import drawing01 from 'src/images/drawing/01.png';
import drawing02 from 'src/images/drawing/02.png';
import drawing03 from 'src/images/drawing/03.png';
import drawing04 from 'src/images/drawing/04.png';
import drawing05 from 'src/images/drawing/05.png';
import drawing06 from 'src/images/drawing/06.png';
import drawing07 from 'src/images/drawing/07.png';
import drawing08 from 'src/images/drawing/08.png';
import { media } from 'src/styles';
import { useSlug } from 'src/utils';

type Props = {
  className?: string;
};

const drawings: string[] = [
  drawing01 as string,
  drawing02 as string,
  drawing03 as string,
  drawing04 as string,
  drawing05 as string,
  drawing06 as string,
  drawing07 as string,
  drawing08 as string,
];

// function random([...array]: string[]): string {
//   const target = array[Math.floor(Math.random() * array.length)];
//   return target;
// }

// function shuffle([...array]: string[]): string[] {
//   for (let i = array.length - 1; i >= 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [array[i], array[j]] = [array[j], array[i]];
//   }
//   return array;
// }

function randomStart([...array]: string[]): string[] {
  const start = Math.floor(Math.random() * array.length);
  for (let i = 0; i < start; i++) {
    const shiftedItem = array.shift();
    if (shiftedItem) {
      array.push(shiftedItem);
    }
  }
  return array;
}

export const Drawing: FC<Props> = ({ className }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const id = setTimeout(() => {
      setShow(true);
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  }, []);
  const { slug } = useSlug();
  const [randomDrawings, setRandomDrawings] = useState(randomStart(drawings));
  useEffect(() => {
    setShow(false);
    const id = setTimeout(() => {
      setRandomDrawings(randomStart(drawings));
      setShow(true);
    }, 300);
    return () => {
      clearTimeout(id);
    };
  }, [slug]);
  return (
    <>
      {/* <Helmet>
        {drawings.map((item, index) => (
          <link
            key={`drawing-preload-${index}`}
            rel="preload"
            href={item}
            as="image"
          />
        ))}
      </Helmet> */}
      <Wrapper
        className={`${className || ''} ${show ? 'show' : 'hide'}`}
        aria-hidden={true}
      >
        {randomDrawings.map((drawing, index) => (
          <img key={`drawing-${index}`} src={drawing} alt="" />
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  &.hide {
    transition: opacity 0.1s linear;
  }
  &.show {
    transition: opacity 0.2s linear;
    opacity: 1;
  }
  img {
    max-width: 68px;
    display: block;
  }
  ${media.ipadVerticalOrMore`
    img {
      max-width: 170px;
    }
  `}
`;

export default Drawing;
