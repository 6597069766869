import type { FC } from 'react';
import { media } from 'src/styles';
import styled from 'styled-components';

import { Navigation } from './navigation';
import { Title } from './title';

type Props = {
  className?: string;
};

export const Header: FC<Props> = ({ className }) => (
  <Wrapper className={className || ''} role="banner">
    <StyledTitle />
    <StyledNavigation />
  </Wrapper>
);

const StyledTitle = styled(Title)`
  ${media.lessThanIpadVertical`
    position: relative;
    z-index: 12;
  `}
  ${media.ipadVerticalOrMore`
    margin-top: 60px;
  `}
`;

const StyledNavigation = styled(Navigation)`
  ${media.lessThanIpadVertical`
    position: absolute;
    z-index: 9;
    top: 0;
  `}
`;

const Wrapper = styled.header`
  display: flex;
  flex-direction: column-reverse;
  ${media.lessThanIpadVertical`
    position: sticky;
    z-index: 10;
    top: ${({ theme }) => theme.structure.margin.top.sp}px;
  `}
`;

export default Header;
