import type { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { media } from 'src/styles';

type Props = {
  className?: string;
  children: ReactNode;
  side?: ReactNode;
  isSideAsHeader?: boolean;
};

export const SinglePageLayout: FC<Props> = ({
  className,
  children,
  side,
  isSideAsHeader = false,
}) => (
  <Wrapper
    className={`${className || ''} ${
      isSideAsHeader ? 'is-side-as-header' : ''
    }`}
  >
    {isSideAsHeader && (
      <>
        <Side as="header">{side && side}</Side>
        <Content>{children}</Content>
      </>
    )}
    {!isSideAsHeader && (
      <>
        <Content>{children}</Content>
        <Side>{side && side}</Side>
      </>
    )}
  </Wrapper>
);

const Side = styled.aside`
  ${media.lessThanIpadVertical`
    text-align: center;
  `}
  ${media.ipadVerticalOrMore`
    grid-row: 1;
    grid-column: 1 / 3;
  `}
`;

const Content = styled.div`
  ${media.lessThanIpadVertical`
    margin-top: 30px;
  `}
  ${media.ipadVerticalOrMore`
    grid-row: 1;
    grid-column: 3 / 7;
  `}
`;

const Wrapper = styled.div`
  ${media.lessThanIpadVertical`
    &:not(.is-side-as-header) {
      display: flex;
      flex-direction: column-reverse;
    }
  `}
  ${media.ipadVerticalOrMore`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: ${({ theme }) => theme.structure.margin.grid.pc}px;
  `}
`;

export default SinglePageLayout;
