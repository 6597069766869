import 'styled-components';

export const theme = {
  font: {
    family: {
      main:
        '游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E, serif',
      tenMincho: 'ten-mincho, serif',
      garamond:
        '"ITC Garamond W01 Book", "ITC Garamond W01 Book Italic", 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E, serif',
    },
    size: {
      xlarge: {
        sp: '2.3rem',
        pc: '2.4rem',
      },
      large: {
        sp: '1.7rem',
        pc: '1.8rem',
      },
      normal: {
        sp: '1.4rem',
        pc: '1.5rem',
      },
      small: {
        sp: '1.1rem',
        pc: '1.2rem',
      },
    },
  },
  letterSpacing: {
    garamond: '0.07em',
  },
  lineHeight: {
    sp: 1.6,
    pc: 2,
  },
  colors: {
    bg: '#fff',
    font: '#000',
    gray: '#9FA0A0',
    lightGray: '#cecece',
    red: '#E60012',
  },
  structure: {
    margin: {
      horizontal: {
        sp: 22,
        pc: 45,
      },
      top: {
        sp: 15,
        pc: 36,
      },
      bottom: {
        sp: 20,
        pc: 48,
      },
      grid: {
        sp: 12,
        pc: 30,
      },
    },
  },
  breakpoints: {
    iphoneVertical: 375,
    ipadVertical: 768,
    ipadHorizontal: 1024,
    large: 1200,
    ipadProHorizontal: 1300,
    siteMaxWidth: 1200,
  },
};

type ThemeType = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends ThemeType {}
}

export default theme;
