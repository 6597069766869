import { useEffect, useState, type FC, type ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  children: ReactNode;
};

export const MountEffect: FC<Props> = ({ className, children }) => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    const id = setTimeout(() => {
      setDidMount(true);
    }, 200);
    return () => {
      clearTimeout(id);
    };
  }, []);
  return (
    <Wrapper className={`${className || ''} ${didMount ? 'mounted' : ''}`}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  transition: opacity 0.2s linear, transform 0.2s ease-out;
  opacity: 0;
  transform: translateY(-3px);
  &.mounted {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export default MountEffect;
