import { useCallback, useContext, useState } from 'react';
import { ArchiveDispatchContext } from 'src/contexts/archive-context';

type ResponseJsonType<T> = {
  result?: {
    data?: {
      [key: string]: {
        nodes: T;
      };
    };
  };
};
const extractNodes = <T>({
  json,
}: {
  json?: ResponseJsonType<T>;
}): T | null => {
  // NOTE:
  // Object.valuesだとanyになるので、
  // ここやり方考える
  let nodes: T | null = null;
  if (json?.result?.data) {
    Object.values(json.result.data).map((value) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (value?.nodes) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        nodes = value.nodes;
      }
    });
  }
  return nodes;
};

type UseFetchNextPageProps<T> = {
  archivePath: string;
  nodes: T | undefined;
};
export const useFetchNextPage = <T extends readonly any[]>({
  archivePath,
  nodes,
}: UseFetchNextPageProps<T>) => {
  const [isFetching, setIsFetching] = useState(false);
  const [nextPage, setNextPage] = useState(2);
  const { dispatch } = useContext(ArchiveDispatchContext);
  const fetchNextPage = useCallback(() => {
    async function fetchData(nextPageNumber: number) {
      setIsFetching(true);
      const response = await fetch(
        `/page-data/${archivePath}/page/${nextPageNumber}/page-data.json`
      );
      const json = (await response.json()) as ResponseJsonType<T>;
      setIsFetching(false);
      setNextPage((value) => value + 1);
      const moreNodes = extractNodes<T>({ json });
      if (nodes && moreNodes && dispatch) {
        if (moreNodes) {
          dispatch({
            type: 'set',
            payload: {
              [archivePath]: [...nodes, ...moreNodes],
            },
          });
        }
      }
    }
    void fetchData(nextPage);
  }, [archivePath, nodes, dispatch, nextPage]);
  return {
    fetchNextPage,
    isFetching,
  };
};
