import type { ImageDataLike } from 'gatsby-plugin-image';
import { useCallback, useMemo, useState, type FC } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { MenuButton, SlideImage } from 'src/components/common';
import { Img } from 'src/components/common/atoms';
import { media, theme } from 'src/styles';
import { nonNullableFilter, useIsMobile } from 'src/utils';
import { Item } from './item';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby');

type Props = {
  images: ReadonlyArray<{
    image: ({ alt: string | null } & ImageDataLike) | null;
  } | null>;
};

const modalDefaultStyle = {
  overlay: {
    zIndex: 100,
    backgroundColor: 'black',
    right: 'initial',
    bottom: 'initial',
    width: '100%',
    height: '100%',
    overflowY: 'auto' as const,
  },
  content: {
    position: 'relative' as const,
    top: 'initial',
    left: 'initial',
    right: 'initial',
    bottom: 'initial',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    width: '60vw',
    maxWidth: '1000px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '200px',
    marginBottom: '150px',
    backgroundColor: 'transparent',
    overflow: 'none',
  },
};

export const GridImageList: FC<Props> = ({ images }) => {
  const [initialIndex, setInitialIndex] = useState(-1);
  const closeModal = useCallback(() => {
    setInitialIndex(-1);
  }, []);
  const isMobile = useIsMobile();
  const modalStyle = useMemo(() => {
    const overlay = { ...modalDefaultStyle.overlay };
    const content = { ...modalDefaultStyle.content };
    if (isMobile) {
      content.width = '100vw';
      content.marginTop = '70px';
      content.marginBottom = 'auto';
    }
    return {
      overlay,
      content,
    };
  }, [isMobile]);

  const nonNullableImages = useMemo(
    () => images.filter(nonNullableFilter),
    [images]
  );
  return (
    <>
      {nonNullableImages.map(
        ({ image }, index) =>
          image && (
            <Item
              key={`grid-image-list-item-${index}`}
              onClick={() => setInitialIndex(index)}
              ariaLabel={image?.alt ?? `Expand picture ${index + 1}`}
            >
              <Img
                file={image}
                alt={image?.alt ?? ''}
                loading="lazy"
                sizes="(max-width: 767px) calc((100vw - 44px - 12px) / 2), (max-width: 1200px) calc((100vw - 90px - 60px) / 2), 380px"
              />
            </Item>
          )
      )}
      <Modal
        isOpen={initialIndex >= 0}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <SlideImage
          images={nonNullableImages}
          initialIndex={initialIndex}
          hasArrowButton={true}
          inModal={true}
        />
        <CloseButtonWrapper lang={'en'}>
          <MenuButton
            onClick={closeModal}
            isPressed={true}
            color={theme.colors.gray}
            ariaLabel={'close button'}
          />
        </CloseButtonWrapper>
      </Modal>
    </>
  );
};

const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  transform: translateY(-100%);
  button {
    &.focus-visible {
      outline: 1px red solid;
    }
  }
  ${media.lessThanIpadVertical`
    top: -15px;
    right: 15px;
  `}
  ${media.ipadVerticalOrMore`
    top: -30px;
    right: 0;
  `}
`;

export default GridImageList;
