import styled, { css } from 'styled-components';

import { Anchor } from './anchor';

const HoverStyle = css`
  outline: none;
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export const StyledHoverImageAnchor = styled(Anchor)`
  text-decoration: none;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  &:hover,
  &.focus-visible {
    .gatsby-image-wrapper {
      ${HoverStyle}
    }
  }
  &:focus-visible {
    .gatsby-image-wrapper {
      ${HoverStyle}
    }
  }
`;
