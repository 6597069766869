import type { PrismicRichTextProps } from '@prismicio/react';
import { graphql } from 'gatsby';
import { useCallback, useMemo, useState, type FC } from 'react';
import styled from 'styled-components';

import {
  Grid,
  GridImageList,
  RichText,
  ViewMoreButton,
} from 'src/components/common';
import { media, styleGaramond } from 'src/styles';
import { nonNullableFilter, useIsMobile } from 'src/utils';
import { SliceContent } from './slice-content';

type SingleEntryType = Queries.CollectionSingleEntryFragment &
  Queries.ProductSingleEntryFragment;
type Props = SingleEntryType & {
  className?: string;
};

export const query = graphql`
  fragment CollectionSingleEntry on PrismicCollectionDataType {
    category {
      document {
        ... on PrismicCollectionCategory {
          data {
            title {
              text
            }
          }
        }
      }
    }
    title {
      text
    }
    content {
      richText
    }
    images {
      image {
        alt
        gatsbyImageData
      }
    }
    body {
      ...CollectionSingleBody
    }
  }

  fragment ProductSingleEntry on PrismicProductDataType {
    category {
      document {
        ... on PrismicProductCategory {
          data {
            title {
              text
            }
          }
        }
      }
    }
    title {
      text
    }
    content {
      richText
    }
    images {
      image {
        alt
        gatsbyImageData
      }
    }
    body {
      ...ProductSingleBody
    }
  }
`;

const LIMIT_NUM_PC = 11;
const LIMIT_NUM_MOBILE = 12;

export const Entry: FC<Props> = ({
  className,
  category,
  title,
  content,
  images,
  body,
}) => {
  const isMobile = useIsMobile();
  const limitNum = isMobile ? LIMIT_NUM_MOBILE : LIMIT_NUM_PC;
  const [showAllImages, setShowAllImages] = useState(
    images && images.length <= limitNum
  );
  const onClickViewMore = useCallback(() => {
    setShowAllImages(true);
  }, []);
  const limitedImages = useMemo(() => {
    if (!images) {
      return null;
    }
    const shownImages = showAllImages ? images : images.slice(0, limitNum);
    return shownImages.filter(nonNullableFilter);
  }, [images, showAllImages, limitNum]);
  const categoryTitle =
    category?.document &&
    'data' in category.document &&
    category.document.data.title;
  return (
    <div className={className || ''}>
      <Grid>
        <Header>
          {(categoryTitle || title?.text) && (
            <Title>
              {categoryTitle && (
                <>
                  {categoryTitle?.text}
                  <br />
                </>
              )}
              {title?.text && <i>{title.text}</i>}
            </Title>
          )}
          {content?.richText && (
            <HeaderContent>
              <RichText
                richText={content.richText as PrismicRichTextProps['field']}
              />
            </HeaderContent>
          )}
        </Header>
        {limitedImages && <GridImageList images={limitedImages} />}
      </Grid>
      {!showAllImages && <ViewMoreButton onClick={onClickViewMore} />}
      {body && <SliceContent content={body} />}
    </div>
  );
};

const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xlarge.sp};
  line-height: 1.5;
  i {
    font-style: italic;
  }
  ${media.ipadVerticalOrMore`
      margin-top: -0.2em;
      font-size: ${({ theme }) => theme.font.size.xlarge.pc};
  `}
`;

const HeaderContent = styled.div`
  margin-top: 10px;
  font-size: ${({ theme }) => theme.font.size.small.sp};
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.small.pc};
  `}
`;

const Header = styled.div`
  ${styleGaramond}
  ${media.lessThanIpadVertical`
    grid-column: 1 / 3;
    text-align: center;
    padding-bottom: calc(30px - 12px);
  `}
`;

export default Entry;
