import type { FilledLinkToDocumentField } from '@prismicio/types';

type CustomDataType = {
  brand?: FilledLinkToDocumentField;
};
export const linkResolver = ({
  type,
  uid = '',
  link_type,
  data,
}: FilledLinkToDocumentField<string, string, CustomDataType>) => {
  if (type === 'post') {
    return `/news/${uid}/`;
  } else if (type === 'collection') {
    return `/collection/${uid}/`;
  } else if (type === 'product') {
    if (data?.brand) {
      return data?.brand?.uid
        ? `/products/${data.brand.uid}/${uid}/`
        : '/products/';
    }
  } else if (type === 'product_brand') {
    return `/products/${uid}/`;
  } else if (type === 'home') {
    return '/';
  } else if (link_type === 'Document') {
    return `/${uid}/`;
  }
  return '/';
};
