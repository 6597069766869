import type { FC } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  html: string;
};

export const Video: FC<Props> = ({ className, html }) => (
  <Wrapper
    className={className || ''}
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

const Wrapper = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  @supports not (aspect-ratio: 16 / 9) {
    padding-top: calc(9 / 16 * 100%);
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default Video;
