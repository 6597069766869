import { createContext, type Dispatch, type Reducer } from 'react';

type ArchiveEntriesFragmentNodesType =
  | Queries.CollectionArchiveEntriesFragment['nodes']
  | Queries.ProductArchiveEntriesFragment['nodes']
  | Queries.StoryArchiveEntriesFragment['nodes'];

export const initialValue = {};

type ArchiveContextType = {
  [key: string]: ArchiveEntriesFragmentNodesType | undefined;
};

export const ArchiveContext = createContext<ArchiveContextType>(initialValue);

type ReducerType = { type: string; payload: ArchiveContextType };

type DispatchContextType = {
  dispatch: Dispatch<ReducerType> | null;
};

export const ArchiveDispatchContext = createContext<DispatchContextType>({
  dispatch: null,
});

export const ArchiveReducer: Reducer<ArchiveContextType, ReducerType> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error('ArchiveReducer error');
  }
};

export default ArchiveContext;
